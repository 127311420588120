import type { Ref } from 'vue'

export default function useNavigationTrap(hasUnsavedChanges: Ref<boolean>) {
  const navigationTrap = reactive<{ isResolving: boolean; resolveNavigation: (shouldStay: boolean) => void }>({
    isResolving: false,
    resolveNavigation: (_: boolean) => {},
  })

  const springTrap = async (escape: () => void) => {
    navigationTrap.isResolving = true

    const shouldStay = await new Promise<boolean>(resolve => {
      navigationTrap.resolveNavigation = resolve
    })

    if (shouldStay) {
      navigationTrap.isResolving = false
    } else {
      escape()
    }
  }

  onBeforeRouteLeave(async (to, _, next) => {
    if (!hasUnsavedChanges.value || ['sorry', '404'].includes(to.name ?? '')) {
      next()
      return
    }

    await springTrap(() => next())
  })

  return {
    navigationTrap,
    springTrap,
  }
}
